import React, { useState, useRef } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import {
  CrossIcon,
} from "@100mslive/react-icons";
import {
  Button,
  Dialog,
  Flex,
  IconButton,
  Text,
  Loading,
  styled,
} from "@100mslive/react-ui";

const iconStyle = {
  color: '#2F80FF', 
  fontSize: '40px',
};

const TextArea = styled("textarea", {
  width: "100%",  
  minHeight: "$24",  
  color: "$textPrimary",
  bg: "$surfaceLight",
  py: "$6",
  pl: "$8",
  r: "$1",
  resize: "none",
  lineHeight: "1rem",
  "&:focus": {
    boxShadow: "none",
    outline: "none",
  },
});

const FeedbackModal = ({ interviewInfo, open, onOpenChange }) => {
  const { roomId, role } = useParams();
  const [feedbackInfo, setFeedbackInfo] = useState({
    loading: false,
    message: ""
  });
  const [count, setCount] = useState(0);
  const apiUrl = process.env.REACT_APP_API;
  const inputRef = useRef(null);

  const validateFeedback = () => {
    if(count <= 0)
    {
      alert("Please set a rating for the candidate.");
      return false;
    }
    else if(inputRef.current.value.trim().length <= 0)
    {
      alert("Please leave a comment about the candidate.");
      return false;
    }
    return true;
  }

  const sendFeedback = () => {
    if(!validateFeedback()){
      return;
    }
    setFeedbackInfo({...feedbackInfo, loading: true}); 
    const feedback = {
      rating: count,
      comment: inputRef.current.value,
      respondent: role === "interviewer" ? "client" : "developer",
    };
    axios.post(`${apiUrl}/interview-feedback/${roomId}`, feedback)
      .then((response) => {
        setFeedbackInfo({loading: false, message: 'Thanks for your feedback!'});          
      })
      .catch((error) => {
        setFeedbackInfo({loading:false, message: 'There was an error sending your feedback, please try again or contact us at hello@simbiosis.team if the problem persists.'});
      });
  };

  const feedbackStep = () => {
    if(feedbackInfo.message === ""){
      return (
        <Flex align="center" justify="center" direction="column"
          css={{ w: "100%", h: "100%", p: "10%"}}
        >
          <Text variant="h5"
            css={{ textAlign: "center" }}
          >
            Give us your feedback about {interviewInfo.profile.name}'s performance on the interview
          </Text>
          <Flex justify="left" gap="4"
            css={{ mt: "$12" }}
          >
            <Button variant="" 
              css={{ p: "0", bg: "$transparent"}}                    
              onClick={() => setCount(1)}                  
              data-testid="feedback_btn_1"
            >
              {count >= 1 ? <AiFillStar style={iconStyle} />               
              : <AiOutlineStar style={iconStyle} />}
            </Button>
            <Button variant="" 
              css={{ p: "0", bg: "$transparent" }}                    
              onClick={() => setCount(2)}  
              data-testid="feedback_btn_2"
            >
              {count >= 2 ? <AiFillStar style={iconStyle} />               
              : <AiOutlineStar style={iconStyle} />}             
            </Button> 
            <Button variant="" 
              css={{ p: "0", bg: "$transparent" }}                    
              onClick={() => setCount(3)}  
              data-testid="feedback_btn_3"
            >
              {count >= 3 ? <AiFillStar style={iconStyle} />               
              : <AiOutlineStar style={iconStyle} />}              
            </Button> 
            <Button variant="" 
              css={{ p: "0", bg: "$transparent" }}                    
              onClick={() => setCount(4)}  
              data-testid="feedback_btn_4"
            >
              {count >= 4 ? <AiFillStar style={iconStyle} />               
              : <AiOutlineStar style={iconStyle} />}              
            </Button> 
            <Button variant="" 
              css={{ p: "0", bg: "$transparent" }}                    
              onClick={() => setCount(5)}  
              data-testid="feedback_btn_5"
            >
              {count >= 5 ? <AiFillStar style={iconStyle} />               
              : <AiOutlineStar style={iconStyle} />}              
            </Button>                               
          </Flex>
          <TextArea
            css={{ mt: "$12" }}
            placeholder="Write comments here"
            ref={inputRef}
            autoFocus
            autoComplete="off"
            aria-autocomplete="none"
          />             
          <Button
            css={{ mt: "$12", w: "50%", minWidth: "180px" }}
            onClick={() => sendFeedback()}                
            data-testid="send_feedback_btn"
          >
            <Text
              variant="sm"
              css={{ fontWeight: "$semiBold", my: "6px" }}
            >
              Send feedback
            </Text>                
          </Button>
        </Flex>
      );
    }
    else {
      return (
        <Flex align="center" justify="center" direction="column"
          css={{ w: "100%", h: "100%", p: "10%"}}
        >
          <Text variant="h5"
            css={{ textAlign: "center" }}
          >
            {feedbackInfo.message}
          </Text>          
        </Flex>
      );
    }
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          css={{
            w: "min(600px, 90%)",
            height: "min(480px, 90%)",
            p: 0,
            r: "$4",
          }}
        >
          <Dialog.Close
            css={{ position: "absolute", right: "$10", top: "$10" }}
          >
            <IconButton as="div" data-testid="dialog_cross_icon">
              <CrossIcon />
            </IconButton>
          </Dialog.Close>

          {interviewInfo.error ?
            <Flex align="center" justify="center" direction="column"
              css={{ w: "100%", h: "100%" }}
            >
              <Text variant="h5"
                css={{ px: "10%", textAlign: "center" }}
              >
                Interview Feedback
              </Text>
              <Text variant="h6"
                css={{ px: "10%", textAlign: "center", mt: "$16" }}
              >
                {interviewInfo.error}
              </Text>
            </Flex>
          : (interviewInfo.project || interviewInfo.profile) && !feedbackInfo.loading ?
            feedbackStep()
          : 
            <Flex align="center" justify="center" direction="column"
              css={{ w: "100%", h: "100%" }}
            >
              <Loading size={100} />
            </Flex>
          }

        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default FeedbackModal;
