import { useState, useEffect } from "react";
import { selectIsConnectedToRoom, useHMSStore } from "@100mslive/react-sdk";
import { BrbIcon, HandIcon, FolderOpenIcon } from "@100mslive/react-icons";
import { Tooltip, Flex } from "@100mslive/react-ui";
import IconButton from "../IconButton";
import { useMyMetadata } from "./hooks/useMetadata";
import axios from 'axios';
import { useParams } from "react-router-dom";

const MetaActions = ({ isMobile = false, compact = false }) => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const { isHandRaised, isBRBOn, isInterviewOn, toggleHandRaise, toggleBRB, toggleInterview } = useMyMetadata();

  const { roomId, role } = useParams();
  const apiUrl = process.env.REACT_APP_API;
  const [isInterviewValid, setInterviewValid] = useState(false);
  // fetch interview info on mount
  useEffect (() => {
    async function fetchData(){
      axios.get(`${apiUrl}/interview-info/${roomId}`)
        .then((response) => {
          if(role === "interviewer" || (role === "interviewee" && response.data.project))
            setInterviewValid(true);
        })
    }
    fetchData();
  }, []);

  if (!isConnected) {
    return null;
  }

  return (
    <Flex align="center" css={{ gap: compact ? "$4" : "$8" }}>
      {isInterviewValid ? 
        <Tooltip title={`${isInterviewOn ? `Close` : `Open`} interview details`}>
          <IconButton onClick={toggleInterview} active={!isInterviewOn} data-testid="interview_btn">
            <FolderOpenIcon />
          </IconButton>
        </Tooltip>
      : null}
      <Tooltip title={`${!isHandRaised ? "Raise" : "Unraise"} hand`}>
        <IconButton
          onClick={toggleHandRaise}
          active={!isHandRaised}
          data-testid={`${
            isMobile ? "raise_hand_btn_mobile" : "raise_hand_btn"
          }`}
        >
          <HandIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={`${isBRBOn ? `I'm back` : `I'll be right back`}`}>
        <IconButton onClick={toggleBRB} active={!isBRBOn} data-testid="brb_btn">
          <BrbIcon />
        </IconButton>
      </Tooltip>
    </Flex>
  );
};

export default MetaActions;
